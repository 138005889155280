<template>
    <div class="container">
        <div class="c-grid-block playlist">
            <table class="table table-hover" v-if="statusLoaded">
                <thead class="thead-dark2">
                <tr>
                    <th scope="col">Titel</th>
                    <th scope="col">Interpret</th>
                    <th scope="col">Album</th>
                    <th scope="col">
                        <div class="text-right">
                            Votes
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="trackEntry in sortedQueue" v-if="!trackEntry.locked_until">
                    <td scope="row">
                        <strong>{{ trackEntry.track.name }}</strong>&nbsp;
                    </td>
                    <td>{{ trackEntry.track.album.artist.name }}</td>
                    <td>{{ trackEntry.track.album.name }}</td>

                    <td>
                        <div class="text-right">
                            <span v-if="trackEntry.track_id != currentTrack"> {{ trackEntry.votes }}</span>
                            <span v-else>Now Playing</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="code">
            <vue-qrcode :value="inviteLink" :width="1000" :quality="1"/>
        </div>
    </div>
</template>

<script>
    import dateformat from 'dateformat'
    import VueQrcode from 'vue-qrcode'

    export default {
        name: 'Queue',
        components: { VueQrcode },
        computed: {
            currentTrack: function () {
                return this.$store.state.roomState.currentTrack
            },
            inviteLink: function () {
                return this.$store.state.roomState.host ? process.env.VUE_APP_CLIENT_URL + '?hash=' + this.$route.params.hash : ''
            },
        },
        methods: {
            formatDate (date) {
                return dateformat(date, "HH:MM")
            },
        }
    }
</script>

<style>
    .navbar {
        display: none !important;
    }

    .container {
        width: 100% !important;
        max-width: none !important;
        display: flex;
    }

    .container .playlist {
        width: 55%;
    }

    .container .code {
        width: 45%;
    }

    .container .code img {
        width: 100%;
    }


    .table {
        width: 100%;
    }

    .table-hover tbody tr:hover td {
        background-color: rgba(69, 153, 55, 0.2);
    }

    .thead-dark2 {
        background-color: rgba(69, 153, 55, 0.2);
    }

    .c-grid-block {
        background-color: rgba(126, 186, 118, 0.1);
        color: #000000;
        /* box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1), 0 0.3rem 0.1rem 0 rgba(0, 0, 0, 0.05);
        */
    }

</style>

